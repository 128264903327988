<template>
    <v-container>
        <Divisor texto="Links Úteis" />
        <v-row justify="start" dense class="ma-0 pa-0 mx-auto">
            <v-col 
                v-for="(i, k) in items" :key="`div${k}`"
                :cols="12" :md="2" class="pa-1 my-auto mx-auto">
                <a :href="i.link" :target="i.target">
                    <v-hover v-slot="{ hover }" close-delay="98" open-delay="155">
        
                        <v-card 
                            class="white--text font-weight-bold" 
                            style="cursor:pointer" 
                            :focus="hover" 
                            :elevation="hover ? 16 : 2"
                            :loading="loading" 
                            :color="hover ? '#67b4c2' : '#5ea4b0'"
                            min-height="130"
                        >
                            <v-card-title class="white--text font-weight-bold">
                                <v-row>
                                    <v-col class="text-center">
                                        {{ i.nome }}
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text class="white--text font-weight-bold">
                                <v-row>
                                    <v-col class="text-center">
                                        <div class="text--truncate text--ellipsis">
                                            {{ i.descricao }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </a>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import api from '@/http'

export default {
    name: 'LinkeisUteis',
    components: {
        Divisor:()=>import('./Divisor.vue'),
    },
    data: () => ({
        loading: false,
        items: [],
    }),
    methods: {
        get_links_uteis() {
            api.get('links/links/')
                .then(res => {
                    this.items = res.data
                    console.log(res.data)
                })
        }
    },
    mounted() {
        this.get_links_uteis()
    }
}
</script>

<style>
    a{
        text-decoration: none;
    }
    .text-truncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Número de linhas a serem exibidas */
    overflow: hidden;
    }
    .text--ellipsis::after {
    content: "..."; /* Os três pontos */
    }
</style>